import request from 'app/http'
import qs from 'qs'

const getTaskUserList = (params) => {
  return request.get('/task/get/private/list', {
    params,
    paramsSerializer: params => {
      return qs.stringify(params, {
        indices: false
      })
    }
  })
}

const getErrHashList = (params) => {
  return request.get('/task/get/hash/error', params)
}

const postTaskUserShare = (params) => {
  return request.post('/task/share', params)
}

const postTaskDelete = (params) => {
  return request.post('/task/batch/delete', params)
}

export {
  getTaskUserList,
  postTaskUserShare,
  postTaskDelete,
  getErrHashList
}
